
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.cursor {
    display: inline-block;
    position: relative;

    animation-name: blink-cursor;
    animation-play-state: running;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-duration: 1s;

    &::before {
        content: '0';
        margin-left: -1ch;
        visibility: hidden;
        top: 0;
        bottom: 0;
    }

    &::after {
        content: '';
        display: block;
        position: absolute;
        width: 2px;
        left: -1px;
        top: -0.1em;
        bottom: 0;
        background-color: #bbf021;
    }

    &.hidden {
        visibility: hidden;
    }
}

@keyframes blink-cursor {
    0% {
        opacity: 1;
    }

    40% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    90% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
