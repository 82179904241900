
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.portal {
    position: fixed;
    bottom: 10px;
    left: 50%;
    width: 0;

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-end;

    z-index: 100000;

}

.openButton {
    position: fixed;
    align-self: stretch;
    right: 50px;
    bottom: 10px;

    padding: 0 16px;
    height: 48px;
    border: 4px solid #3c5475;
    box-shadow: 4px 2px 8px #0e1f31;
    background-color: #0e1f31;
    color: #acd7ff;
    cursor: pointer;

    transform: translateY(0);
    transition: transform 0.2s ease-in-out;

    &.opened {
        transform: translateY(100px);
    }
}

.container {
    position: absolute;

    border: 4px solid #3c5475;

    background-color: #0e1f31;
    box-shadow: 4px 2px 8px #0e1f31;
    padding: 10px;

    transform: translateY(0);
    transition: transform 0.2s ease-in-out;
    max-width: 98vw;
    overflow-x: auto;
    overflow-y: hidden;

    &.closed {
        transform: translateY(100%);
        transition-delay: 0.2s;

        &:hover {
            transition-delay: 0s;
            transform: translateY(80%);
        }

        > .content {
            transform: translateY(50px);
            opacity: 0;
        }
    }
}

.content {
    display: flex;
    gap: 20px;

    transition: all 0.2s ease-in-out;
    opacity: 1;
    transform: translateY(0);
}

.keyboard {
    --desktop-keyboard-button-size: 30px;


    @media screen and (max-width: 1400px) {
        --desktop-keyboard-button-size: 25px;
    }

    @media screen and (max-width: 1200px) {
        --desktop-keyboard-button-size: 20px;
    }

}

.controls {
    display: grid;
    align-content: end;
    gap: 4px;
    font-size: 12px;

    .control {
        border: none;
        margin: 0;
        padding: 0 10px;

        cursor: pointer;

        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #2d3f56;
        color: #acd7ff;

        white-space: nowrap;

        &[disabled] {
            opacity: 0.3;
        }
        font-size: inherit;

        &.highlight {
            background-color: #acd7ff;
            box-shadow: 0 0 5px #acd7ff;
            color: #2d3f56;
        }
    }
}
