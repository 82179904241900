
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.label {
    --color: white;
    --x: 0;
    --y: 0;
    --offset-x: 0;
    --offset-y: 0;

    --offset-x-percent: calc(var(--offset-x) * 100%);
    --offset-y-percent: calc(var(--offset-y) * 100%);
    --rotateDeg: 0;

    position: absolute;
    left: calc(var(--x) * 100%);
    top: calc(var(--y) * 100%);
    transform: translate(var(--offset-x-percent), var(--offset-y-percent)) rotate(var(--rotateDeg));
    color: var(--color);
    padding: 0.6em 0.5em 0.4em;
    transform-origin: 50% 0;

    &.shadow {
        filter: drop-shadow(0px 0px 5px var(--color));
    }

    &.isAngle {
        padding: 30px;
    }

    &.altOrigin {
        transform-origin: center bottom;
    }

    pointer-events: none;
}

.inputWrapper {
    pointer-events: initial;
}

.input {
    color: inherit;

    &.bold {
        font-weight: bold;
    }

    &.small {
        font-size: 12px;
    }
}

.border {
    border: 1px solid rgba(255, 255, 255, 0.4);
    padding: 3px;
}

.button {
    mask-image: url("~@viuch/assets/icons/arrow-bottom.svg");
    mask-repeat: no-repeat;
    mask-size: contain;
    mask-origin: content-box;
    background-color: white;
    border: none;
    margin: 0;
    padding: 4px;
    outline: none;

    width: 24px;
    height: 24px;

    position: absolute;
    top: calc(50%);
    right: 0;
    transform: translateX(100%) translateY(-50%) rotate(var(--_rotate));
    --_rotate: 0deg;
    filter: drop-shadow(0 0 2px white);

    &.up {
        --_rotate: 180deg;
    }
}
