
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.wrapper {
    position: relative;
    display: inline-flex;
    justify-content: center;
    margin: 0 0.1em;
    padding-top: 0.6em;
    min-width: 1.3em;
}

.icon {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
}
