
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.wrapper {
    --dropShadowLarge: rgba(67, 155, 255, 0.1);
    --dropShadowSmall: rgba(5, 80, 166, 0.2);
    --frameOuterColor: #324c68;
    --frameInnerColor: #1b324d;

    // filter: drop-shadow(0px 4px 80px var(--dropShadowLarge)) drop-shadow(0px 4px 10px var(--dropShadowSmall));

    &.disableShadows {
        --dropShadowLarge: transparent;
        --dropShadowSmall: transparent;
    }
}

.frameOuter {
    clip-path: polygon(6px 0, 0 5px, 0 calc(100% - 16px), 18px 100%, 100% 100%, 100% 17px, calc(100% - 19px) 0);
    background-color: var(--frameOuterColor);
    height: 100%;
    max-height: inherit;

    &_reversed {
        clip-path: polygon(
            16px 0,
            calc(100% - 6px) 0,
            100% 6px,
            100% calc(100% - 16px),
            calc(100% - 16px) 100%,
            0 100%,
            0 16px
        );
    }
}

.frameInner {
    clip-path: polygon(
        8px 2px,
        8px calc(100% - 18px),
        26px calc(100% - 2px),
        calc(100% - 2px) calc(100% - 2px),
        calc(100% - 2px) 18px,
        calc(100% - 20px) 2px
    );

    background-color: var(--frameInnerColor);
    padding: 2px 2px 2px 8px;
    height: 100%;
    max-height: inherit;

    &_reversed {
        padding: 2px 8px 2px 2px;

        clip-path: polygon(
            18px 2px,
            calc(100% - 8px) 2px,
            calc(100% - 8px) 8px,
            calc(100% - 8px) calc(100% - 18px),
            calc(100% - 24px) calc(100% - 2px),
            2px calc(100% - 2px),
            2px 18px
        );
    }
}
