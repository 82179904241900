
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.wrapper {
    display: block;
    height: 100%;
    overflow: hidden;
    position: relative;

    --topPadding: 0;
    --bottomPadding: 0;
}

.area {
    height: 100%;
    padding: var(--topPadding) 11% var(--bottomPadding) 10%;

    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
}

.container {
    height: 0;
}

.toolbar {
    position: absolute;
    top: 0;
    right: 0;
    display: grid;
    grid-auto-flow: column;
    grid-gap: 2px;
}

.button {
    display: inline-block;
    border: none;
    border-radius: 0;
    margin: 0;
    padding: 0;
    width: 32px;
    height: 32px;
    outline: none;
    background-color: #324c68;
}

.icon {
    width: 24px;
    height: 24px;
    margin: 4px;
    background-color: #439bff;

    mask-repeat: no-repeat;
    mask-size: contain;
    mask-position: center;
    mask-origin: content-box;
    mask-image: url("~@viuch/assets/icons/trash-bin-instrument.svg");
}
