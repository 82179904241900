
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.button {
    border: none;
    cursor: pointer;
    font-family: inherit;
    touch-action: none;

    background-color: #2d3f56;
    color: #acd7ff;
    text-align: center;
    font-size: 20px;
    height: 48px;

    @include screenM {
        height: 36px;
    }

    display: flex;
    align-items: center;
    justify-content: center;

    overflow: hidden;
    position: relative;
    user-select: none;
    outline: none;

    &.alternate {
        background-color: #24364c;
        color: white;
    }

    &:active {
        background-color: #3c5475;
    }
}
