
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.newline {
    margin-top: 0.4em;
}

.caret {
    display: inline;
    &::before {
        content: '0';
        margin-right: -1ch;
        user-select: none;
        visibility: hidden;
    }
}
