
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.root {

    --bg-color: var(--darkBlueOpal-light);
    --icon-color: var(--blue);
    position: relative;

    cursor: pointer;

    &:hover {
        --bg-color: var(--darkBlueOpal100);
        --icon-color: var(--blue);
    }

    &._disabled {
        --bg-color: var(--darkBlueOpal-light);
        --icon-color: var(--blue-alpha15);
    }

    &._active {
        --bg-color: var(--darkBlueOpal100);
        --icon-color: var(--blue);
    }
}

.button {
    width: 36px;
    height: 36px;

    background-color: var(--bg-color);
    color: var(--icon-color);
}

.chunk {
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;

    border: 4px solid currentColor;
    border-top-color: transparent;
    border-left-color: transparent;

    pointer-events: none;
}

.nestedMenu {
    position: absolute;

    top: -2px;
    left: 100%;
}

.portal {
    position: absolute;
    --offset-left: 0px;
    --offset-top: 0px;
    top: var(--offset-top);
    left: var(--offset-left);
    //transform: translate(var(--offset-left), var(--offset-top));
}
