
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.root {
    padding: 16px;

    position: relative;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    column-gap: 16px;
    row-gap: 32px;
}

.instrument {
    width: 400px;
    height: 400px;
}

.column {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: stretch;
    justify-content: flex-start;
    gap: 8px;
}

.row {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    gap: 8px;
}

.title {
    @extend %header3;
}

.name {
    @extend %header4;
}

.section {
    padding: 8px;
    display: block;

    &:hover {
        background-color: #00000009;
    }
}

.errorText {
    color: red;
    font-size: 0.8rem;
    overflow: hidden;
    white-space: normal;
    width: 100%;
}
