
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.line {
    height: 0;
    max-height: 0;

    display: flex;
    align-items: center;

    margin-left: -40px;
    margin-right: -12px;
    z-index: 1;

    position: relative;

    > .lineWithDotsWrapper {
        height: 2px;
        flex-grow: 1;
        position: relative;
        touch-action: none;

        > .lineWithDots {
            height: 100%;
            width: 100%;
            background-color: transparent;
            position: relative;

            &::before {
                content: '';
                position: absolute;
                width: 100%;
                height: 10px;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }

    > .lineRemoveButton {
        color: #ff603d;
        background-color: rgba(#ff603d, 0.15);
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 16px;
        cursor: pointer;
        border-radius: 100px;
        border: none;
        outline: none;
        flex-shrink: 0;
    }

    > .lineEnding {
        width: 12px;
        height: 2px;
        position: relative;
        background-color: #acd7ff;
        z-index: -1;
        margin-top: -2px;

        &::before,
        &::after {
            content: '';
            position: absolute;
            right: 0;
            background-color: #acd7ff;
            height: 2px;
            width: 12px;
        }

        &::before {
            transform: translate(3px, -4px) rotate(45deg);
        }

        &::after {
            transform: translate(3px, 4px) rotate(-45deg);
        }
    }
}

.intervals {
    display: flex;
    flex-wrap: nowrap;
    height: 0;
}

.dots {
    display: contents;
}


.label {
    &Container {
        position: absolute;
        left: 0;
        bottom: calc(var(--linesGap) / 5);

        width: 1px;
        height: 1px;

        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: flex-start;
        justify-content: flex-end;
    }

    &Math {
        overflow: unset;
        color: var(--blue);
        text-shadow: -1px 1px 4px black;

        &__inner {
            color: currentColor;

        }
    }
}
