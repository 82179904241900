
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: stretch;
    justify-content: flex-start;

    padding: 2px;
    gap: 2px;

    width: 170px;

    background-color: #1B2F46;
}

.item {
    flex-grow: 0;
    flex-shrink: 0;
}
