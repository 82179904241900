
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.wrapper {
    display: inline-block;
    margin: 0 0.1em;
}

.sqrt {
    display: inline-block;
}

.body {
    display: inline-block;
    vertical-align: bottom;
}

.container {
    position: relative;
    vertical-align: bottom;
    display: inline-block;
}

.container > svg {
    width: 100%;
    height: 100%;
    position: absolute;
    pointer-events: none;
}

.container > svg > line {
    stroke: currentColor;
    stroke-width: 0.1em;
}

.container > svg > line:last-child {
    stroke-width: 0.2em;
}

.content {
    margin: 0.2em 0.2em 0 1.3em;
    display: inline-block;
}

.index {
    display: inline-block;
    vertical-align: bottom;
    margin-right: -0.7em;
    font-size: 0.7em;
}
