
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.item {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.positions {
    flex: 1 1 auto;
    margin-right: 10px;
}

.position {
    white-space: nowrap;
}

.body {
    display: flex;
    align-items: center;
    width: 100%;
    margin-right: 10px;
}

.inputWrapper {
    border: 1px solid #1b324d;
    border-radius: 5px;
    padding: 5px;
    min-width: 200px;
    max-width: 200px;
    margin-left: 10px;
}

.input {
    color: #000;
}

.colorsWrapper {
    margin-right: 10px;
    background: rgba(128, 128, 128, 0.37);
    padding: 5px;
    display: grid;
    justify-items: center;
}

.colors {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.colorButton {
    border: 0;
    outline: 0;
    width: 50px;
    height: 20px;
    cursor: pointer;
}
