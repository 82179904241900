
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.content {
    position: absolute;
}

.small {
    font-size: calc(var(--desktop-keyboard-button-size) / 3);

    @include fingerOnly {
        font-size: 20px;
    }

    @include screenM {
        font-size: 16px;
    }
}

.large {
    font-size: calc(var(--desktop-keyboard-button-size) / 2.5);

    @include fingerOnly {
        font-size: 20px;
    }

    @include screenM {
        font-size: 16px;
    }
}
