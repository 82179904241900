
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.container {
    position: relative;
    width: 100%;
    max-width: 540px;
    margin: 64px auto 0;
    border: 2px solid darkorange;
    padding: 24px;

    h2 {
        margin-bottom: 24px;
    }
}

.error {
    color: red;
    margin: 12px 0;
}
