@use 'breakpoints' as *;

@mixin renderSizes($size, $height) {
    font-size: $size;
    line-height: $height;

    &_m {
        @include screenM {
            font-size: $size;
            line-height: $height;
        }
    }

    &_t {
        @include screenT {
            font-size: $size;
            line-height: $height;
        }
    }

    &_mt {
        @include screenMT {
            font-size: $size;
            line-height: $height;
        }
    }

    &_d {
        @include screenD {
            font-size: $size;
            line-height: $height;
        }
    }

    &_l {
        @include screenL {
            font-size: $size;
            line-height: $height;
        }
    }

    &_dl {
        @include screenDL {
            font-size: $size;
            line-height: $height;
        }
    }
}

%title {
    font-family: Plateia, sans-serif;
    font-style: normal;
    font-weight: bold;
    text-transform: uppercase;
}

%title {
    &1 {
        @extend %title;
        @include renderSizes(18px, 24px);
    }

    &2 {
        @extend %title;
        @include renderSizes(16px, 24px);
    }

    &3 {
        @extend %title;
        @include renderSizes(14px, 20px);
    }

    &4 {
        @extend %title;
        @include renderSizes(12px, 18px);
    }

    &5 {
        @extend %title;
        @include renderSizes(10px, 18px);
    }

    &6 {
        @extend %title;
        @include renderSizes(8px, 12px);
    }
}

%header,
%paragraph {
    font-family: Nunito, sans-serif;
    font-style: normal;
    font-weight: bold;
}

%header {
    &1 {
        @extend %header;
        @include renderSizes(24px, 28px);
    }

    &2 {
        @extend %header;
        @include renderSizes(18px, 24px);
    }

    &3 {
        @extend %header;
        @include renderSizes(16px, 20px);
    }

    &4 {
        @extend %header;
        @include renderSizes(14px, 18px);
    }
}

%paragraph {
    font-weight: normal;
    font-size: 20px;
    line-height: 22px;
}

%paragraph {
    &1 {
        @extend %paragraph;
        @include renderSizes(20px, 22px);
    }

    &2 {
        @extend %paragraph;
        @include renderSizes(16px, 20px);
    }

    &3 {
        @extend %paragraph;
        @include renderSizes(14px, 18px);
    }

    &4 {
        @extend %paragraph;
        @include renderSizes(12px, 14px);
    }
}
