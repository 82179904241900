
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.wrapper {
    display: flex;
    flex-direction: column;
}

.input {
    margin-bottom: 0.2rem;
}

.autoModeCheckbox {
    display: flex;
    align-items: center;

    &__input {
        margin: 0 5px 0 0;
    }

    &__label {
        font-size: 12px;
        cursor: pointer;
    }
}
