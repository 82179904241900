
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.modalLayout {
    --modalCloseDropShadowLarge: #439bff33;
    --modalCloseDropShadowSmall: #0550a67f;
    --modalCloseColor: #acd7ff3f;

    display: grid;
    grid-template-rows: auto 1fr;
    height: 100%;
}

.modalHeader {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: baseline;
    justify-content: flex-start;
    grid-gap: 2em;

    margin: 16px 32px 0;

    @include screenM {
        margin: 15px 18px 0 12px;
    }
}

$icons: (
    'green': #bbf021,
    'blue': #439bff,
    'purple': #d969db,
);

.modalIcon {
    margin-right: 6px;
    width: 12px;
    height: 12px;
    border-radius: 100px;

    @include screenM {
        width: 8px;
        height: 8px;
    }

    @each $class, $color in $icons {
        &:global(.#{unquote($class)}) {
            background-color: $color;
        }
    }
}

.modalTitle {
    overflow: hidden;
    margin: 0;

    @extend %title3;
    @extend %title4_m;
}

.modalClose {
    margin-left: auto;
    flex-shrink: 0;
    align-self: flex-start;
}

.modalCloseButton {
    background-color: unset;
    border: 0;
    border-radius: 0;
    outline: 0;
    width: 24px;
    height: 24px;
    padding: 0;

    filter: drop-shadow(0px 4px 10px var(--modalCloseDropShadowLarge)) drop-shadow(0px 3px 5px var(--modalCloseDropShadowSmall));

    @include screenM {
        width: 20px;
        height: 20px;
    }

    > .modalCloseIcon {
        width: 100%;
        height: 100%;
        background-color: var(--modalCloseColor);

        mask-repeat: no-repeat;
        mask-size: contain;
        mask-position: center;
        mask-origin: content-box;
        mask-image: url("~@viuch/assets/icons/close.svg");
    }
}

.modalContent {
    padding: 16px 32px 22px;
    cursor: default;
    overflow: hidden;
    position: relative;

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: stretch;
    justify-content: flex-start;

    @include screenM {
        padding: 15px 18px 40px 12px;
    }
}
