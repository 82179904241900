
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.inline {
    display: inline-block;
}

.wrapper {
    min-height: 3.5em;
    margin-left: -0.4em;
}

.char {
    margin: 0 0.2em;
}

.integralChar {
    transform: translate(0.15em, -0.1em);
    font-size: 1.9em;
    line-height: 0;
}

.integralWrapper {
    margin-right: 0.3em;
    display: inline-grid;
    grid-gap: 3px;
    justify-items: start;
    grid-template-rows: 1fr 0 1fr;
    vertical-align: middle;
}

.smallContent {
    font-size: 0.8em;
}

.topContent {
    align-self: end;
    margin-right: 0.8em;
    transform: translateX(1em) translateY(-1.1em);
}

.bottomContent {
    align-self: start;
    margin-right: 0.8em;
    transform: translateX(0.6em) translateY(0.9em);
}

.argContent {
    height: 0;
}
