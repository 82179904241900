
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.perpendicular {
    transform: translateY(0.1em);

    path {
        stroke: currentColor;
    }
}

.parallel {
    transform: translateY(-0.1em);
    display: inline-block;
}

.icon {
    width: 1.5em;
    height: 1.5em;
    vertical-align: bottom;
    margin-bottom: -0.2em;
}