
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;

    --linesPaddingTop: 80px;
    --linesPaddingBottom: 80px;
}

.simplebar {
    --scrollbar-right: 8px;

    height: 100%;
    overflow: auto;

    // display: flex;
    // flex-direction: column;

    :global(.simplebar-content) {
        min-height: 100%;
        display: flex;
        flex-direction: column;
    }
}

.track {
    padding-top: 42px;
    padding-bottom: 36px;
    right: 6px;

    @include screenM {
        right: 2px;
        padding-bottom: 6px;
    }
}

.instrumentContainer {
    flex-grow: 1;
    flex-shrink: 0;

    position: relative;
    min-height: 100%;

    display: grid;
    align-content: center;

    &._readonly {
        pointer-events: none;
    }
}

.instrumentContent {
    min-height: 100%;

    display: grid;
    align-content: center;
}

.linesContainerWrapper {
    padding: var(--linesPaddingTop) 40px var(--linesPaddingBottom) 60px;
}

.linesContainer {
    --linesGap: calc(16px * 5);

    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: 0;
    grid-gap: var(--linesGap);
}

.leftBlock {
    position: absolute;
    left: 0;
    top: 0;
}

.rectangle {
    height: 20px;
    width: 36px;
    background: #2d3f56;
    margin-bottom: 2px;

    @include screenM {
        display: none;
    }

    @include screenT {
        display: none;
    }
}

.addLineButton {
    background: #2d3f56;
    height: 36px;
    width: 36px;
    border: none;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.plusIcon {
    fill: #acd7ff;
}
