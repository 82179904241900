$breakpointM-T: 768;
// $breakpointT-D: 1024;
$breakpointT-D: 1280;
$breakpointD-L: 1600;

@mixin screenM {
    @media (max-width: #{$breakpointM-T - 1}px) {
        @content;
    }
}

@mixin screenT {
    @media (min-width: #{$breakpointM-T}px) and (max-width: #{$breakpointT-D - 1}px) {
        @content;
    }
}

@mixin screenD {
    @media (min-width: #{$breakpointT-D}px) and (max-width: #{$breakpointD-L - 1}px) {
        @content;
    }
}

@mixin screenL {
    @media (min-width: #{$breakpointD-L}px) {
        @content;
    }
}

@mixin fingerOnly {
    @media (hover: none) and (pointer: coarse), (hover: none) and (pointer: fine) {
        @content;
    }
}

@mixin mouseOnly {
    @media (hover: hover) and (pointer: fine) {
        @content;
    }
}

@mixin penOnly {
    @media (hover: none) and (pointer: coarse) {
        @content;
    }
}

@mixin screenMT {
    @media (max-width: #{$breakpointT-D - 1}px) {
        @content;
    }
}

@mixin screenTD {
    @media (min-width: #{$breakpointM-T}px) and (max-width: #{$breakpointD-L - 1}px) {
        @content;
    }
}

@mixin screenDL {
    @media (min-width: #{$breakpointT-D}px) {
        @content;
    }
}

@mixin screenTDL {
    @media (min-width: #{$breakpointM-T}px) {
        @content;
    }
}
