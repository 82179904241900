
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import "~@viuch/shared/styles/simplebar";
@import "~@viuch/shared/styles/colors";

@font-face {
    font-family: "Plateia";
    font-display: swap;
    src: url("../assets/fonts/Plateia.woff") format("woff");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Nunito";
    font-display: swap;
    src: url("../assets/fonts/Nunito-Regular.woff") format("woff");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Nunito";
    font-display: swap;
    src: url("../assets/fonts/Nunito-Bold.woff") format("woff");
    font-weight: 700;
    font-style: normal;
}

* {
    box-sizing: border-box;
}

html,
body,
#root {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

body {
    font-family: Nunito, Roboto, Arial, sans-serif;
    font-size: 16px;
}

// фикс проблемы с оверлеем

#webpack-dev-server-client-overlay {
    display: none;
}
