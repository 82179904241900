
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.wrapper {
    display: inline-block;
    padding: 0.1em 0.3em;
}

.content {
    vertical-align: middle;
    display: inline-block;
}

.contentInner {
    display: inline-grid;
    grid-auto-flow: row;
    grid-gap: 7px;

    padding: 0.1em 0.3em;
}

.bracket {
    vertical-align: middle;
}
