
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.container {
  padding: 10px;
  display: flex;
  flex-flow: column;
  gap: 4px;
  background-color: #00000009;
}

.row {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  gap: 4px;
}

.field {
  flex-grow: 1;
}

.deleteButton {
  display: block;
  width: 24px;
  padding: 0;

  &__icon {
    vertical-align: bottom;
    display: inline-block;
    width: 16px;
    height: 16px;
    mask-image: url("~@viuch/assets/icons/delete.svg");
    mask-repeat: no-repeat;
    mask-size: contain;
    mask-position: center;
    mask-origin: content-box;
    background-color: currentColor;
  }
}
