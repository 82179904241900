
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.wrapper {
    display: contents;

    white-space: pre-wrap;
}

.root {
    display: inline-block;
    max-width: 100%;

    pointer-events: none;
}

.mathField {
    color: currentColor;
    vertical-align: middle;
}

.mathFieldWrapper {
    vertical-align: baseline;
    display: inline-table;
}

.mathFieldContent {
    padding: 0;
}

.baseline {
    display: inline-block;
    vertical-align: baseline;
    line-height: 1;
    visibility: hidden;
    pointer-events: none;
    user-select: none;
    margin-right: -1ch;

    &__inner {
        display: inline-table;
        vertical-align: baseline;
        --offset: 0;
        margin-bottom: calc(var(--offset) * 1px - 0.02em);
        padding-bottom: calc(0.15em - 1px);
    }
}

.mathFieldOuter {
    display: inline-table;
    vertical-align: bottom;
    line-height: 1;
}

.mathTagWrapper {
    display: inline-block;
    vertical-align: baseline;
}

.textBlock {
    display: inline-block;

    &__inner {
        display: inline;
    }
}
