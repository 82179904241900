
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.wrapper {
    display: inline-block;
    border-top: 0.1em solid currentColor;
    padding-top: 0.2em;
    position: relative;
    margin: 0.1em 0.2em 0;

    &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        transform: translate(0.1em, -0.25em);
        border-top: 0.2em solid transparent;
        border-bottom: 0.2em solid transparent;
        border-left: 0.3em solid currentColor;
    }
}

.container {
    padding: 0 0.12em;
}
