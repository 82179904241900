
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.wing_top {
    --clipPath: polygon(
        6px 6px,
        calc(100% - 40px) 6px,
        calc(100% - 35px) 0,
        100% 0,
        100% 100%,
        0 100%,
        0 11px,
        6px 6px
    );

    --outerColor: var(--goodGreen);
    --innerColor: var(--darkBlueOpal-linear);
    --innerPadding: 8px 2px 2px 8px;
}

.wing_bottom {
    --clipPath: polygon(
        6px 0,
        100% 0,
        100% 100%,
        35% 100%,
        calc(35% - 5px) calc(100% - 6px),
        0 calc(100% - 6px),
        0 5px
    );

    --outerColor: var(--brightBlue);
    --innerColor: var(--darkBlueOpal-linear);
    --innerPadding: 2px 2px 8px 8px;
}

.wrapper {
    position: relative;
}

.inner {
    position: relative;
    height: 100%;

    clip-path: var(--clipPath);
    background: var(--outerColor);
    padding: var(--innerPadding);
}

.children {
    background: var(--innerColor);
    position: relative;
    height: 100%;
}
