
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.inline {
    display: inline-block;
}

.wrapper {
    margin: 0 0.1em;
    display: inline-block;
}

.base {
    font-size: 0.8em;
    margin-top: -0.4em;
    vertical-align: top;
}

.degree {
    display: inline-block;
    font-size: 0.8em;
    margin-bottom: 0.7em;
    vertical-align: bottom;
}

.indices {
    display: inline-flex;
    flex-direction: column;
}
