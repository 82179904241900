
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.selectionPointer {
    position: fixed;

    width: 20px;
    height: 20px;
    color: #bbf021;

    transform: translateX(-50%);
    z-index: 1999;

    touch-action: none;
    -webkit-touch-action: none;

    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.selectionPointerImg {
    position: absolute;
}
