
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.wrapper {
    position: relative;
    touch-action: none;

    &.hold {
        filter: drop-shadow(0px 4px 80px rgba(67, 155, 255, 0.2))
            drop-shadow(0px 4px 10px rgba(5, 80, 166, 0.5));
    }
}

.button {
    width: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.icon {
    width: 100%;
    height: 100%;
}

.childThumb {
    position: absolute;
    bottom: 0;
    right: 0;
    border: 4px solid transparent;
    border-right: 4px solid #acd7ff80;
    border-bottom: 4px solid #acd7ff80;
}

.buttonHoldFill {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #324c68;
}

.holdContainerWrapper {
    z-index: 1;
    position: absolute;
    bottom: 100%;
    left: 0;
    right: 0;
    overflow: visible;
    min-height: 1px;

    &.hidden {
        visibility: hidden;
        opacity: 0;
    }
}

.holdContainer {
    position: relative;
    background-color: #324c68;

    display: inline-grid;
    grid-template-rows: 1fr;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    vertical-align: bottom;
    transform: translateX(var(--offset));
}

.buttonWrapper {
    display: block;

    &.hold {
        background-color: #324c68;
    }
}

.blurred {
    filter: blur(3px);
    pointer-events: none;
}

.delimiter {
    height: 100%;
    width: 2px;
    background: linear-gradient(
        261.38deg,
        #1a2b41 -1.44%,
        #1d334b 46.29%,
        #1a2b41 100.39%
    );
}
