
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.result {
    display: inline-block;
    margin: 0 0.1em;
    transform: translateY(0.1em);
}
