
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.wrapper {
    flex-grow: 1;

    background-color: var(--darkBlueOpal100);

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: stretch;
    justify-content: flex-start;

    padding: 2px 18px 2px 8px;

    clip-path: polygon(
        6px 0,
        calc(100% - 28px) 0,
        100% 22px,
        100% calc(100% - 33px),
        calc(100% - 16px) 100%,
        6px 100%,
        0 calc(100% - 5px),
        0 calc(100% - 62px),
        35px calc(100% - 117px),
        35px 117px,
        0 62px,
        0 5px
    );
}

.inner {
    flex-grow: 1;
    height: 100%;

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: stretch;
    justify-content: flex-start;

    background: rgba(27, 47, 70, 0.8);

    clip-path: polygon(
            0 0,
            calc(100% - 24px) 0,
            100% 22px,
            100% calc(100% - 31px),
            calc(100% - 17px) 100%,
            0 100%,
            0 calc(100% - 59px),
            30px calc(100% - 111px),
            30px 111px,
            0 59px,
            0 0
    );
}
