
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.wrapper {
    width: 400px;
    height: 400px;
}

.instrument {
    margin: 0 auto;
    width: 100%;
    height: 100%;
}

.popup {
    min-width: 300px;
}

.backdrop {
    --backdropBackground: #eee5;
}

.frame {
    --frameOuterColor: orange;
    --frameInnerColor: white;
}
