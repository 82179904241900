
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
    .container {
    position: relative;
    white-space: nowrap;
    line-height: 1;
}

.inlineWrapper {
    display: inline-block;
}

.element {
    padding-top: 0.1em;
    margin-top: -0.1em;

    &.attention {
        color: var(--primary-green);
    }

    &.default {
        color: var(--redAlarm);
    }

    &.selected {
        background-color: #acd7ff80;
    }
}

.crossOut {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    overflow: visible;

    &__line {
        stroke: #ffffffbd;
        stroke-width: 1.5px;
        stroke-linecap: round;
    }
}
