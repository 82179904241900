
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.wrapper {
    height: 0;
    position: relative;

    margin-right: -12px;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
}

.line {
    background-color: #acd7ff;
    height: 2px;
    flex-grow: 1;
    flex-shrink: 0;
    position: relative;

    > .clickArea {
        margin: -5px 0 -5px 0;
        height: 12px;
    }

    > .dots {
        position: absolute;
        width: 100%;
        height: 0;
        top: 1px;
    }
}

.arrow {
    flex-shrink: 0;

    left: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #acd7ff;
    height: 2px;
    width: 12px;

    &::after,
    &::before {
        content: '';
        position: absolute;
        right: 0;
        background-color: #acd7ff;
        height: 2px;
        width: 12px;
    }

    &::before {
        transform: translate(3px, -4px) rotate(45deg);
    }

    &::after {
        transform: translate(3px, 4px) rotate(-45deg);
    }

    > .axis {
        position: absolute;
        top: 10px;
        color: white;
        user-select: none;
        white-space: nowrap;
    }
}
