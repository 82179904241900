
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.toolbar {
    transform: translate3d(0, 0, 0);
    pointer-events: none;
}

.element {
    pointer-events: initial;
}
