
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.dashed {
    stroke-dasharray: 8px 8px;
}

.hidden {
    visibility: hidden;
}

.rect {
    width: 0.001px;
    height: 12px;

    transform-box: fill-box;
    transform-origin: center center;
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * -1deg + var(--rotate))) translate(50%, 50%);

    fill: transparent;

    --rotate: 160deg;

    &._second {
        --rotate: -160deg;
    }
}