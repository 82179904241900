
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.mathLabelContainer {
    display: flex;
    width: 1px;
    height: 1px;
    align-items: center;
    justify-content: center;

    transform: translate(var(--translateX), var(--translateY));

    user-select: none;
    pointer-events: none;

    --offsetY: 8px;
    --offsetX: 10px;

    --translateX: 0;
    --translateY: 0;

    &._down {
        --translateY: var(--offsetY);
        align-items: flex-start;
    }

    &._up {
        --translateY: calc(var(--offsetY) * -1);
        align-items: flex-end;
    }

    &._right {
        --translateX: var(--offsetX);
        justify-content: flex-start;
    }

    &._left {
        --translateX: calc(var(--offsetX) * -1);
        justify-content: flex-end;
    }

}

.mathLabel {
    color: currentColor;

    &__formula {
        max-width: unset;
    }

}
