
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.wrapper {
    padding: 30px 24px;

    @include fingerOnly {
        padding: 24px 7px;
    }
}

.top {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    gap: 2px;
    margin-bottom: 2px;

    @include fingerOnly {
        margin-bottom: 4px;
    }
}

.mathButton {
    color: white;
}

.button {
    background-color: #24374e;
}

.changeTabButton {
    color: #acd7ff;
    background: #24374e;
    font-size: 18px;

    @include screenM {
        font-size: 16px;
    }

    &.selected {
        color: #2d3f56;
        background: #bbf021;
        font-weight: 800;
        font-family: 'Nunito-Extra-Bold', 'Nunito';
    }
}

.content {
    display: grid;
    grid-auto-rows: auto;
    grid-gap: 2px;

    @include fingerOnly {
        //padding: 0 4px;
    }
}

.icon {
    width: 100%;
    height: 100%;
}
