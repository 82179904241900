
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.wrapper {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: auto;
    grid-auto-rows: auto;
    align-items: start;
    justify-content: start;

    grid-gap: 16px;
}

.settings {
    min-width: 200px;
    max-width: 350px;
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 1fr;

    grid-gap: 8px;
}

.frame {
    height: 400px;
    width: 400px;
}
