
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.denominatorChar {
    display: inline-block;
    margin-right: 0.1em;
}

.inline {
    display: inline-block;
}
