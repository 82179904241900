
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.root {
    position: relative;
    width: 100%;
    height: 100%;

    transform: translate(0); // создать контекст наложения
}


.rootSvg {
    position: absolute;
    touch-action: none;

    svg,
    foreignObject {
        overflow: visible;
    }
}
