
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.backdrop {
    --backdropBackground: #0e233ae6;

    background-color: var(--backdropBackground);
    padding: 0 20px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;

    backdrop-filter: blur(1px);

    @include screenM {
        padding: 0 8px;
    }
}

.area {
    height: 100%;
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
}
