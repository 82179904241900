
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.wrapper {
    --editor-frame-outer: var(--darkBlueOpal100);
    --editor-frame-inner: #1c334e;

    // filter: drop-shadow(0px 4px 80px #439bff19) drop-shadow(0px 4px 10px #0550a633);

    &.disableShadows {
        filter: none;
    }
}

.outer {
    height: 100%;
    position: relative;
    background-color: var(--editor-frame-outer);

    clip-path: polygon(
            0 0,
            calc(100% - 23px) 0,
            calc(100% - 6px) 18px,
            calc(100% - 6px) 20%,
            100% calc(20% + 5px),
            100% 100%,
            6px 100%,
            6px 35%,
            0 calc(35% - 5px),
            0 0);
}

.inner {
    height: 100%;
    position: relative;
    padding: 2px 8px 6px 8px;
    background: var(--editor-frame-inner);

    clip-path: polygon(
            8px 2px,
            calc(100% - 33px) 2px,
            calc(100% - 8px) 29px,
            calc(100% - 8px) calc(100% - 6px),
            8px calc(100% - 6px),
            8px 2px);
}
