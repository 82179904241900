
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.root {
    padding: 8px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
    justify-content: flex-start;
    gap: 24px;
}

.group {
    &__label {
        @extend %header3;
        margin-bottom: 8px;
    }

    &__items {
        display: block;
    }
}
