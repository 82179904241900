
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.inline {
    display: inline-block;
}

.wrapper {
    margin: 0.4em 0.2em 1em -0.3em;
}

.char {
    margin: 0 0.2em;
}

.integralChar {
    transform: translate(0.15em, 0.1em);
    font-size: 1.9em;
    line-height: 0;
}

.contentsWrapper {
    display: inline-flex;
    flex-direction: column;
    gap: 3px;
}

.complexIntegralContentsWrapper {
    vertical-align: bottom;
    margin-bottom: calc(-1em + calc(1em / 4));
}

.smallContent {
    font-size: 0.8em;
}

.topContent {
    transform: translateX(0.2em);
}

.argContent {
    height: 0;
}
