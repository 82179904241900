
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.buttons {
    position: absolute;
    bottom: 14px;
    right: 14px;

    display: inline-grid;
    grid-auto-flow: row;
    grid-gap: 4px;
}

.button {
    border-radius: 100px;
    background-color: #324C68;
    border: none;
    outline: none;
    padding: 0;

    width: 24px;
    height: 24px;

    &__icon {
        color: #439BFF;
        width: 100%;
        height: 100%;

        background-color: currentColor;
        mask-repeat: no-repeat;
        mask-size: contain;
        mask-position: center;
        mask-origin: content-box;

        &._plus {
            mask-image: url("./plus.svg");
        }

        &._minus {
            mask-image: url("./minus.svg");
        }
    }
}
