
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.wrapper {
    position: relative;
    height: 100%;
    touch-action: none;
    text-shadow:
        #1b324d 0 0 2px,
        #1b324d 0 0 2px,
        #1b324d 0 0 2px,
        #1b324d 0 0 2px,
        #1b324d 0 0 2px,
        #1b324d 0 0 2px,
        #1b324d 0 0 2px,
        #1b324d 0 0 2px,
        #1b324d 0 0 2px,
        #1b324d 0 0 3px,
        #1b324d 0 0 5px;
}
