
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.container {
    position: relative;
    width: 100%;
    padding-bottom: 48px;

    min-height: 100%;
}

.main {
    padding: 48px 24px 0;
}
