
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.header {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 24px;
    box-shadow: 0 2px 4px 0 rgba(black, 0.2);
}

.logo {
    margin: 0;
}

.logo a {
    color: black;
    text-decoration: none;
}

.logout {
    border: none;
    background-color: inherit;
    padding: 8px 12px;
    color: red;
    cursor: pointer;
    font-size: 16px;

    &:hover,
    &:focus {
        color: darkred;
    }
}
