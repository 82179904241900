
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.fullscreenSvg {
    pointer-events: none;
    overflow: visible;
}

.innerSvg {
    pointer-events: all;
    overflow: visible;
}
