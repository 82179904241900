
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.intervalWrapper {
    // position: absolute;
}

.intervalBody {
    width: 100%;
    height: 0;
    position: relative;
    display: flex;
    align-items: center;
}

.intervalPlusButton,
.intervalMinusButton {
    position: absolute;
    width: 16px;
    height: 16px;
    background: #324c68;
    border-radius: 50%;
    left: 50%;
    transform: translateX(-50%);
    color: #acd7ff;
    border: none;
    cursor: pointer;

    &.inactive {
        opacity: 0.2;
    }
}

.intervalPlusButton {
    top: -22px;
    padding: 0;

    >.plusIcon {
        width: 100%;
        height: 100%;
        fill: #acd7ff;
    }

    &.active {
        background: transparent;

        >.plusIcon {
            fill: #21f0a5;
        }
    }
}

.intervalMinusButton {
    top: 2px;
    padding: 0;

    >.minusIcon {
        width: 80%;
        height: 100%;
    }

    &.active {
        background: transparent;

        >.plusIcon {
            fill: #ff603d;
        }
    }
}