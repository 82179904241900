
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.wrapper {
    display: inline-block;
    margin: 0 0.1em;
}

.degree {
    display: inline-block;
    margin-bottom: 0.7em;
    vertical-align: bottom;
    font-size: 0.7em;
}

.inline {
    display: inline-block;
}
