
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.spaceButtonWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
}

.spaceButton {
    flex-grow: 10;
}

.spaceLabel {
    font-size: 14px;
    opacity: 0.5;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.spaceArrowButton {
    width: 48px;
    flex-grow: 0;
    flex-shrink: 0;

    @include screenM {
        width: 24px;
    }
}

.spaceArrowIcon {
    color: white;
}
