
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.grid {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    visibility: hidden;
    pointer-events: none;

    --gridColor: #314b67;
    --gridSize: 16px;
    --gridOffsetTop: 0px;
    --gridOffsetLeft: 0px;

    background-image: linear-gradient(
            to right,
            var(--gridColor) 0px,
            var(--gridColor) 1px,
            transparent 1px
        ),
        linear-gradient(
            to bottom,
            var(--gridColor) 0px,
            var(--gridColor) 1px,
            transparent 1px
        );
    background-repeat: repeat;
    background-position: var(--gridOffsetLeft) var(--gridOffsetTop);
    background-size: var(--gridSize) var(--gridSize);
}
