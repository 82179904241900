:root,
::after,
::before {
    --primary-green: #bbf021;
    --primary-green-alpha80: #bbf021cc;
    --primary-green-alpha70: #bbf021b2;
    --primary-green-alpha50: #bbf02180;
    --primary-green-alpha10: #bbf0211a;

    --white: #fff;
    --white-alpha50: #ffffff80;
    --white50: #ffffff80;

    --blue: #acd7ff;
    --blue50: #acd7ff80;
    --blue-alpha5: #ACD7FF0C;
    --blue-alpha10: #ACD7FF19;
    --blue-alpha15: #acd7ff26;
    --blue25: #acd7ff40;
    --blue-alpha25: #acd7ff40;
    --blue-alpha30: #acd7ff4c;
    --blue-alpha50: #acd7ff80;
    --blue-alpha70: #acd7ffb2;
    --blue-alpha80: #acd7ffcc;

    --brightBlue: #439bff;
    --brightBlue-alpha20: #439bff33;

    --darkBlueOpal-light: #2d3f56;
    --darkBlueOpal100: #324c68;

    --darkBlueOpal-linear: linear-gradient(261.38deg, #1a2b41 -1.44%, #1d334b 46.29%, #1a2b41 100.39%);

    --redAlarm: #ff603d;
    --goodGreen: #21f0a5;
    --orange: #f18921;

    --maroon: #ff3d60;
    --azure: #4eeeee;
    --yellow: #fff73a;
    --aquamarine: #01dc9a;
    --orchid: #d969db;
    --carrot: #FE881C;
    --graySquirrel: #7e848c;
    --grayBlue: #38495c;
    --blackSlate: #2b2b2b;
}
