
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.wrapper {
    --shadow-color: #324c68;

    // filter: drop-shadow(0 0 10px var(--shadow-color));

    &.disableShadows {
        --shadow-color: transparent;
    }
}

.frameOuter {
    position: relative;
    height: 100%;
    width: 100%;
    clip-path: polygon(
        0 17px,
        19px 0,
        calc(100% - 6px) 0,
        100% 5px,
        100% calc(100% - 16px),
        calc(100% - 18px) 100%,
        0 100%
    );
    background-color: #324c68;
}

.frameInner {
    height: 100%;
    clip-path: polygon(
        2px 18px,
        20px 2px,
        calc(100% - 8px) 2px,
        calc(100% - 8px) calc(100% - 18px),
        calc(100% - 26px) calc(100% - 2px),
        2px calc(100% - 2px)
    );
    background-color: #1b324d;
    padding: 2px 8px 2px 2px;
}
