
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.svg {
    overflow: visible;

    --line-color: transparent;
 
    &.positive {
        --stroke-color: #21f0a5;
        --fill-color: #21f0a520;
        --line-color: #acd7ff;
    }
    
    &.negative {
        --stroke-color: #ff603d;
        --fill-color: #ff603d20;
        --line-color: #acd7ff;
    }
    
    &.none {
        --stroke-color: transparent;
        --fill-color: transparent;
        --line-color: transparent;
    }
    
    &.zero {
        --line-color: #439bff;
        --fill-color: transparent;
        --line-color: #acd7ff;
    }
}

.path {
    stroke: var(--stroke-color);
    stroke-width: 2px;
    fill: none;
}

.fill {
    fill: var(--fill-color);
    stroke: none;
}

.line {
    stroke: var(--line-color);
    stroke-width: 2px;
    fill: none;
}