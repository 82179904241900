
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
$scrollbarColor: #439bff;

.track {
    --size: 4px;
    position: absolute;
}

.thumb {
    border-radius: 0;
    background: $scrollbarColor;
    position: relative;
    cursor: default;
}

.trackY {
    @extend .track;
    width: var(--size);
    height: 100%;
    top: 0;
    right: 0;
}

.trackX {
    @extend .track;
    width: 100%;
    height: var(--size);
    left: 0;
    bottom: 0;
}

.thumbY {
    @extend .thumb;
    width: 100%;
    clip-path: polygon(100% 5px, 5px 100%, 0 calc(100% - 5px), 0 0);
}

.thumbX {
    @extend .thumb;
    height: 100%;
    clip-path: polygon(calc(100% - 5px) 0, 100% 100%, 5px 100%, 0 0);
}

.wrapper {
    width: 100%;
    height: 100%;
    inset: 0 0 10px;
}

.scrollbar {
    width: 100%;
    height: 100%;
}

.scroller {
    outline: 0;
}
