
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.spinner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    &::after {
        content: "";
        display: block;
        width: 50px;
        height: 50px;
        border-radius: 100px;
        position: relative;

        border: 10px solid rgba(143, 143, 143, 0.2);
        border-left-color: #8F8F8F;
        animation: load8 1.1s infinite linear;
    }

    &.backdrop {
        background-color: rgba(0, 0, 0, 0.2);
    }

    &.allowEvents {
        pointer-events: none;
    }
}

@keyframes load8 {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
