
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.baseline {
    display: inline-flex;
    margin-right: -1ch;

    &::before {
        content: '0';
        visibility: hidden;
        pointer-events: none;
        user-select: none;
    }
}
