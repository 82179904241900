
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.wrapper {
    position: absolute;
    left: var(--dotOffset);
    top: 0;

    width: 0;
    height: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    &:focus {
        .dot {
            background-color: #bbf021;
        }

        .mathInput {
            color: #bbf021;
        }
    }
}

.dot {
    position: absolute;
    background-color: white;
    touch-action: none;

    &.line {
        width: 2px;
        height: 8px;
    }

    &.circle {
        width: 8px;
        height: 8px;
        border-radius: 100px;
    }

    display: flex;
    align-items: center;
    justify-content: center;

    > .dotClickable {
        position: absolute;
        border-radius: 100px;
        width: 16px;
        height: 16px;

        touch-action: none;
    }
}

.name {
    position: absolute;
    pointer-events: none;
    bottom: 3px;
}

.value {
    position: absolute;
    pointer-events: none;
    top: 9px;
}

.mathInput {
    color: white;
}
