
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.wrapper {
    position: relative;
    // filter: drop-shadow(0px 4px 80px #439BFF19) drop-shadow(0px 4px 10px #0550A633);

    &.disableShadows {
        filter: none;
    }
}

.outer {
    position: relative;
    height: 100%;
    background-color: var(--darkBlueOpal100);

    clip-path: polygon(
            0 0,
            calc(100% - 26px) 0,
            100% 26px,
            100% calc(100% - 6px),
            calc(100% - 5px) 100%,
            40% 100%,
            40% calc(100% - 6px),
            18px calc(100% - 6px),
            0 calc(100% - 24px),
    );
    padding: 2px 2px 8px;
}

.inner {
    position: relative;
    height: 100%;
    background-color: #1B334D;

    clip-path: polygon(
            0 0,
            calc(100% - 25px) 0,
            100% 25px,
            100% 100%,
            16px 100%,
            0 calc(100% - 16px),
    );
}
