
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.wrapper {
    display: inline-block;
    margin: 0 1px;
}

.fraction {
    display: inline-grid;
    grid-gap: 1px;
    place-content: center;
    text-align: center;
    vertical-align: bottom;
    position: relative;
}

.container {
    padding: 1px 0.3em;
}

.line {
    display: inline-block;
    width: 100%;
    height: 1px;
    background: currentColor;
}
