
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.tabs {
    flex: 0 0 60px;
    width: 60px;
    display: grid;
    grid-template: repeat(3, 1fr) / 1fr;
    gap: 3px;
}

.selected {
    background: #324c68;
    text-shadow: 0px 2px 4px rgba(172, 215, 255, 0.5);
}
