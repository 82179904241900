
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.content {
    display: grid;
    grid-auto-flow: row;
    grid-gap: 8px;
}

.backdrop {
    --backdropBackground: #eee5;
}

.frame {
    --frameOuterColor: orange;
    --frameInnerColor: white;
}
