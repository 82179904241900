
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.wrapper {
    --editor-frame-outer: var(--darkBlueOpal100);
    --editor-frame-inner: #1c334e;

    // filter: drop-shadow(0px 4px 80px #439bff19) drop-shadow(0px 4px 10px #0550a633);

    &.disableShadows {
        filter: none;
    }

    &.desktop {
        --outerPath: polygon(
                0 0,
                calc(23px) 0,
                calc(6px) 18px,
                calc(6px) 20%,
                0 calc(20% + 5px),
                0 100%,
                calc(100% - 6px) 100%,
                calc(100% - 6px) 35%,
                100% calc(35% - 5px),
                100% 0);

        --innerPath: polygon(
                calc(100% - 8px) 2px,
                33px 2px,
                8px 29px,
                8px calc(100% - 6px),
                calc(100% - 8px) calc(100% - 6px),
                calc(100% - 8px) 2px);

        --outerPadding: 0;
        --innerPadding: 2px 8px 6px 8px;
    }

    &.tablet {
        --outerPath: polygon(
                0 0,
                100% 0,
                100% 100%,
                calc(50% + 5px) 100%,
                50% calc(100% - 6px),
                0 calc(100% - 6px),
                0 0
        );

        --innerPath: none;

        --outerPadding: 2px 8px 8px 2px;
        --innerPadding: 0;
    }

    &.mobile {
        --outerPath: polygon(
                0 0,
                100% 0,
                100% calc(100% - 11px),
                calc(100% - 6px) calc(100% - 6px),
                50% calc(100% - 6px),
                calc(50% - 5px) 100%,
                0 100%,
                0 0
        );

        --innerPath: none;

        --outerPadding: 2px 8px 8px 2px;
        --innerPadding: 0;
    }
}

.outer {
    height: 100%;
    position: relative;
    background-color: var(--editor-frame-outer);

    padding: var(--outerPadding);
    clip-path: var(--outerPath);
}

.inner {
    height: 100%;
    position: relative;
    background-color: var(--editor-frame-inner);

    padding: var(--innerPadding);
    clip-path: var(--innerPath);
}

