
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.shift {
    color: #fff;
}

.pressedShift {
    background: #324c68;
    text-shadow: 0px 2px 4px rgba(172, 215, 255, 0.5);
}

.shiftIcon {
    width: 100%;
    height: 100%;
}
