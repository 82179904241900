
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.customColorTool {

    > div {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        justify-content: flex-start;
        gap: 8px;

        > button {
            border: 1px solid gray;

            width: 32px;
            height: 32px;
            margin: 0;
            padding: 0;

            background-color: var(--color);
        }
    }
}