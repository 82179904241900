
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.spaceRow {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-auto-rows: auto;
    grid-gap: 2px;

    @include screenM {
        grid-template-columns: repeat(6, 1fr);
    }
}

.buttonIcon {
    width: 100%;
    height: 100%;
}

.spaceButton {
    grid-column-end: span 8;

    @include screenM {
        grid-column-end: span 4;
    }
}

.enterLabel {
    font-size: 12px;
    font-weight: bold;
    color: white;
}

.langButtonWrapper {
    position: relative;
    color: white;
}

.langButton {
    width: 100%;
    padding: 2px;

    @include screenTD {
        padding: 6px;
    }
}

.langMenu {
    position: absolute;
    left: 0;
    bottom: calc(100% + 3px);
}
