
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.outer {
    --clip-path: polygon(0 0, 100% 0, 100% 100%, 20px 100%, 0 calc(100% - 14px));
    --frame-color: var(--blue);
    --frame-background: var(--darkBlueOpal-linear);

    clip-path: var(--clip-path);
    background: var(--frame-color);

    padding: 2px;
    position: relative;
    overflow: hidden;

    display: flex;
    flex-direction: column;
}

.inner {
    position: relative;
    overflow: hidden;

    clip-path: var(--clip-path);
    background: var(--frame-background);

    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
}
