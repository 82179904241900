
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.box {
    width: 18px;
    height: 18px;
}

.icon {
    path {
        fill: currentColor;
    }
}
