
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.svg {
    width: 56px;
    height: 56px;

    pointer-events: none;

    &.absolute {
        position: absolute;

        &.center {
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }

}

.innerSvg {
    overflow: visible;
}

.circle1 {
    stroke: var(--blue);
    fill: none;
}

.circle2 {
    stroke: var(--blue25);
    fill: none;
}
