
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.wrapper {
    display: inline-block;
}

.inlineWrapper {
    display: inline-block;
}

.container {
    font-size: 0.7em;
}

.content {
    margin-top: calc(16px - 0.5em);
    vertical-align: top;
}
