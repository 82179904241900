
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.wrapper {
    --dropShadowLarge: rgba(67, 155, 255, 0.1);
    --dropShadowSmall: rgba(5, 80, 166, 0.2);
    --frameOuterColor: #324c68;
    --frameInnerColor: #1b324d;

    // filter: drop-shadow(0px 4px 80px var(--dropShadowLarge))
    // drop-shadow(0px 4px 10px var(--dropShadowSmall));

    &.disableShadows {
        --dropShadowLarge: transparent;
        --dropShadowSmall: transparent;
    }

    --outerPath: polygon(6px 0, 0 5px, 0 calc(100% - 16px), 18px 100%, 100% 100%, 100% 0);

    --innerPath: polygon(
        8px 2px,
        8px calc(100% - 18px),
        26px calc(100% - 2px),
        calc(100% - 2px) calc(100% - 2px),
        calc(100% - 2px) 2px
    );
    --innerPadding: 2px 2px 2px 8px;

    &.variant-2 {
        --outerPath: polygon(0 0, calc(100% - 6px) 0, 100% 5px, 100% 100%, 0 100%);

        --innerPath: none;
        --outerPadding: 2px 8px 2px 2px;
        --innerPadding: 0;
    }

    &.variant-3 {
        --frameOuterColor: var(--blue);
    }
}

.frameOuter {
    clip-path: var(--outerPath);
    background: var(--frameOuterColor);
    height: 100%;
    max-height: inherit;
    padding: var(--outerPadding);
}

.frameInner {
    clip-path: var(--innerPath);
    background: var(--frameInnerColor);
    padding: var(--innerPadding);
    height: 100%;
    max-height: inherit;
}
