
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.wrapper {
    display: flex;
    flex-direction: row;
    place-items: start start;
    gap: 20px;
    padding: 20px 0;
}

.instrument {

    &.large {
        width: 650px;
        height: 650px;
    }
}
