
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.block {
    --columns: 4;
    --rows: 4;

    display: grid;
    gap: 2px;
    grid-template:
        repeat(var(--rows), var(--desktop-keyboard-button-size))
        / repeat(var(--columns), var(--desktop-keyboard-button-size));
}

.icon {
    width: 90%;
    height: 90%;
}
