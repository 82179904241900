
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.iconButton {
    position: absolute;
    right: 0;
    top: 0;
    background: #324c68;
    height: 32px;
    border: none;
    cursor: pointer;
}
