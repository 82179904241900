
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.icon {
    display: flex;
    width: 100%;
    height: 100%;
    background-color: currentColor;

    mask-repeat: no-repeat;
    mask-size: contain;
    mask-position: center;
    mask-origin: content-box;
}
