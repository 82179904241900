
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.button {
    position: relative;
    border: none;
    padding: 0;
    outline: none;
    background-color: transparent;
    margin: 0;

    display: inline-grid;
    grid-template-columns: auto;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    grid-gap: 2px;

    user-select: none;

    color: var(--toolbar-button-color);
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    > .icon,
    > .label {
        background-color: var(--toolbar-button-background);
    }

    &.active {
        > .icon > .buttonIcon,
        > .label > .text {
            filter: drop-shadow(0px 2px 4px #acd7ff7f);
        }

        > .icon,
        > .label {
            background-color: var(--toolbar-button-background-active);
        }

        > .icon {
            > .buttonIcon {
                opacity: 0.5;
            }

            > .openInnerButton {
                opacity: 0.25;
            }
        }

        > .label > .text {
            opacity: 0.5;
        }
    }

    &[disabled] {
        > .icon,
        > .label {
            background-color: var(--toolbar-button-background-disabled);
        }

        > .icon {
            > .buttonIcon {
                opacity: 0.25;
            }

            > .openInnerButton {
                opacity: 0.25;
            }
        }

        > .label > .text {
            opacity: 0.25;
        }
    }

    &:hover:not([disabled]) {
        > .icon,
        > .label {
            background-color: var(--toolbar-button-background-active);
        }
    }
}

.icon {
    width: 36px;
    height: 36px;

    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;
    overflow: hidden;

    > .buttonIcon {
        width: 100%;
        height: 100%;

        > .buttonIconInner {
            height: 100%;
            background-color: currentColor;
        }
    }

    > .openInnerButton {
        position: absolute;

        width: 8px;
        height: 8px;
        bottom: 0;
        right: 0;

        border: 4px solid transparent;
        border-right-color: currentColor;
        border-bottom-color: currentColor;

        pointer-events: none;
    }
}

.label {
    text-align: left;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 4px;
    white-space: nowrap;

    > .text {
        @extend %paragraph4;
    }
}
