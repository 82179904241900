
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.wrapper {
    display: flex;
    flex-direction: column;
}

.title {
    font-size: 12px;
    margin-bottom: 4px;
}

.bar {
    height: 5px;
    background-color: lightgrey;
    border-radius: 5px;

    &Fill {
        height: 100%;
        background-color: lightseagreen;
        border-radius: 5px;
        max-width: 100%;
    }
}
