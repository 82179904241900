
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.rows {
    display: grid;
    grid-template-columns: repeat(20, 1fr);
    grid-gap: 2px;
}

.row {
    display: contents;
}

.charButton {
    grid-column-end: span 2;
}

.button,
.shift {
    grid-column-end: span 3;
}
