
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.layout {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;

    color: var(--white);

    width: 100%;
    padding: 30px 30px 38px 32px;

    @include screenM {
        padding: 8px 15px 22px 12px;
    }
}

.content {
    display: flex;
    overflow-x: hidden;
    overflow-y: auto;
    flex-shrink: 1;

    --scrollbar-right: 24px;
    --scrollbar-left: var(--scrollbar-right);

    @include screenM {
        --scrollbar-right: 20px;
    }
}

.contentInner {
    position: relative;
}

.header {
    flex-shrink: 0;

    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto;
    margin-bottom: 12px;

    > .closeButton {
        display: inline-block;

        width: 24px;
        height: 24px;
        border: 0;
        border-radius: 0;
        outline: 0;
        padding: 0;
        background-color: unset;
        margin: 0 0 0 10px;

        cursor: pointer;

        filter: drop-shadow(0px 4px 10px var(--modalCloseDropShadowLarge))
            drop-shadow(0px 3px 5px var(--modalCloseDropShadowSmall));

        &::after {
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            background-color: var(--blue-alpha25);

            mask-repeat: no-repeat;
            mask-size: contain;
            mask-position: center;
            mask-origin: content-box;
            mask-image: url("~@viuch/assets/icons/close.svg");
        }
    }
}

.title {
    margin-top: 14px;
    @extend %title3;
    @extend %title4_m;
}

.subtitle {
    @extend %title5;
    color: var(--blue50);
}
