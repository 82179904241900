
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.list {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: stretch;
    justify-content: flex-start;
    gap: 8px;
}

.listItem {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 4px;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 4px;

    &__math {
        flex-grow: 1;
        flex-shrink: 1;
    }

    &__button {
        flex-grow: 0;
        flex-shrink: 0;
    }
}

.button {
    width: 32px;
    height: 32px;

    display: flex;
    align-items: center;
    justify-content: center;

    &::after {
        content: '';
        display: inline-block;
        width: 32px;
        height: 32px;

        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        background-origin: content-box;
    }

    &.plus::after {
        background-image: url("~@viuch/assets/icons/plus-black.svg");
    }

    &.remove::after {
        background-image: url("~@viuch/assets/icons/delete.svg");
    }
}
