@import "~simplebar-react/dist/simplebar.min.css";

[data-simplebar="init"] {
    // ширина/высота полоски трека
    --scrollbar-track-offset: 6px;
    --scrollbar-right-track-offset: var(--scrollbar-track-offset, 18px);
    --scrollbar-bottom-track-offset: var(--scrollbar-track-offset, 18px);

    // отступы трека
    --scrollbar-right-track-margin: 0px;
    --scrollbar-right-track-margin-top: var(--scrollbar-right-track-margin);
    --scrollbar-right-track-margin-bottom: var(--scrollbar-right-track-margin);
    --scrollbar-bottom-track-margin: 0px;
    --scrollbar-bottom-track-margin-left: var(--scrollbar-bottom-track-margin);
    --scrollbar-bottom-track-margin-right: var(--scrollbar-bottom-track-margin);

    // дополнительное пространство для скроллбара
    --scrollbar-right-space: 0px;
    --scrollbar-bottom-space: 0px;

    margin-right: calc(var(--scrollbar-right-space) * -1);

    .simplebar-content-wrapper {
        padding-right: var(--scrollbar-right-space);
    }

    .simplebar-track.simplebar-vertical {
        z-index: unset;
        width: var(--scrollbar-right-track-offset);
        margin: var(--scrollbar-right-track-margin-top) 0 var(--scrollbar-right-track-margin-bottom) 0;

        .simplebar-scrollbar {
            &::before {
                content: "";
                background-color: var(--brightBlue);
                width: 4px;
                clip-path: polygon(0 0, 100% 5px, 100% 100%, 0 calc(100% - 5px));
                opacity: 1;
            }
        }
    }

    .simplebar-track.simplebar-horizontal {
        z-index: unset;
        height: var(--scrollbar-bottom-track-offset);
        margin: 0 var(--scrollbar-bottom-track-margin-right) 0 var(--scrollbar-bottom-track-margin-left);

        .simplebar-scrollbar {
            &::before {
                content: "";
                background-color: var(--brightBlue);
                height: 4px;
                clip-path: polygon(5px 0, 0 100%, calc(100% - 5px) 100%, 100% 0);
                opacity: 1;
            }
        }
    }
}

[data-simplebar="init"] {
    //display: flex;

    .simplebar-wrapper {

    }

    .simplebar-mask {
        outline: none;
    }

    .simplebar-offset {
        //outline: none;
    }

    .simplebar-content-wrapper {
        display: flex;
        flex-direction: column;
    }

    .simplebar-content {
        flex-grow: 1;
        flex-shrink: 1;
        //min-height: 100%;
    }

    &[data-shrink="true"] .simplebar-content {
        height: 100%;
    }
}
