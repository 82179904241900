
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.wrapper {
    margin: 0 0.1em;

    display: inline-flex;
    flex-direction: row;
    align-items: center;
}
