
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.wrapper {
    position: relative;

    width: 15vw;
    height: 0;
    padding-bottom: 80%;

    @include screenTD {
        width: 13vw;
        padding-bottom: 65%;
    }
}

.icon {
    position: absolute;
    width: 100%;
    height: 100%;
    font-size: 1.5em;

    &.hover {
        color: #2d3f56;
        background: #bbf021;
    }
}

.textIcon {
    @extend .icon;

    &.hover {
        color: #2d3f56;
        background: #bbf021;
    }
}
