
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.wrapper {
    --outerPath: polygon(
        0 0,
        calc(100% - 24px) 0,
        calc(100% - 6px) 18px,
        calc(100% - 6px) 89px,
        100% 94px,
        100% 100%,
        0 100%,
        0 0
    );

    --innerPath: polygon(
        2px 2px,
        calc(100% - 33px) 2px,
        calc(100% - 8px) 29px,
        calc(100% - 8px) calc(100% - 6px),
        2px calc(100% - 6px),
        2px 2px
    );

    --frameBackground: var(--darkBlueOpal100);
    --contentBackground: #1B334D;

    clip-path: var(--outerPath);
    background: var(--frameBackground);

    height: 100%;
    overflow: hidden;
    position: relative;
}

.outer {
    clip-path: var(--innerPath);

    height: 100%;
    overflow: hidden;
    position: relative;
    padding: 2px 8px 6px 2px;

    background: var(--contentBackground);
}

.inner {
    height: 100%;
    overflow: hidden;
    position: relative;
}
