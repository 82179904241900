
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.input {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: stretch;
    justify-content: stretch;
    min-width: 0;

    > span {
        font-size: 12px;
        flex-shrink: 0;
        place-self: flex-start;
    }

    &__inline {
        display: inline-flex;
    }
}
