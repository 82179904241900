
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.input {
    color: black;
    min-width: 200px;
    max-width: 300px;
}

.inputWrapper {
    padding: 6px;
}
