
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.alignments {
    display: grid;
    grid-template: repeat(3, 20px) / repeat(3, 20px);
    border: 1px solid gray;
    border-radius: 5px;
    overflow: hidden;
}

.alignmentRadioWrapper {
    position: relative;
    display: flex;
    color: gray;
    font-size: 14px;

    &.middle {
        align-items: center;
        justify-content: center;
    }

    &.top {
        align-items: flex-start;
    }

    &.bottom {
        align-items: flex-end;
    }

    &.left {
        justify-content: flex-start;
    }

    &.right {
        justify-content: flex-end;
    }

    &.selected {
        color: #fff;
        background-color: darkorange;
    }

    > .alignmentRadio {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        opacity: 0;
    }
}
