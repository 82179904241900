
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.container {
    height: 0;
    overflow: hidden;
}

.wrapper {
    :global {
        .codex-editor {
            z-index: unset;

            &:not(:focus-within) {
                .codex-editor__redactor {
                    transition: padding-bottom 0.5s ease 0.3s;
                    padding-bottom: 0;
                }
            }

            &:focus-within {
                .codex-editor__redactor {
                    transition: padding-bottom 0.5s ease;
                    padding-bottom: 200px;
                }
            }
        }

        .codex-editor--toolbox-opened {
            // position: initial;
        }

        .codex-editor__redactor {
        }

        .ce-toolbar--opened,
        .ce-settings--opened {
            z-index: 1000;
            //left: 20px;
        }

        .font-families-list {
            position: absolute;
            right: 0;
            top: 100%;
            background-color: #fff;
            box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
            display: grid;
            grid-row-gap: 5px;

            &__item {
                padding: 3px;
                cursor: pointer;
                list-style: none;
                font-size: 14px;

                &.active {
                    color: #fff;
                    background: #388ae5;
                }
            }
        }

        .ce-block__content {
            margin: 0 50px;
            max-width: 100%;
        }

        .ce-toolbar__content {
            max-width: calc(100% - 100px);
        }


        .cdx-list--unordered {
            padding-left: 0;

            > .cdx-list__item {
                position: relative;
                padding-left: 0;
                list-style-type: none;

                &::before {
                    content: 'x';
                    display: inline-block;
                    color: transparent;
                    width: 24px;
                    left: 0;
                    height: 16px;
                    line-height: 1;

                    background-image: url("~@viuch/assets/icons/list-right-arrow.svg");
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: left center;
                    background-origin: content-box;
                }
            }
        }

        .cdx-list--ordered {
            padding-left: 0;

            list-style-type: none;
            counter-reset: ordered-list;

            > .cdx-list__item {
                counter-increment: ordered-list;
                padding: 4px 0 4px 0;
                position: relative;

                &::before {
                    content: counter(ordered-list) ".";
                    display: inline-block;
                    width: 24px;
                }
            }
        }

        [data-font] {
            &[data-font="Nunito"] {
                font-family: Nunito, sans-serif;
            }

            &[data-font="Plateia"] {
                font-family: Plateia, sans-serif;
            }
        }

    }

    $colors: #bbf021,
        #fff,
        #acd7ff,
        #2e86e0,
        #324c68,
        #ff603d,
        #21f0a5;

    @each $color in $colors {
        [data-color='"" + $color'] {
            color: $color;
        }
    }
}
