
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.table {
    border-collapse: collapse;
    width: 100%;

    td {
        border: 1px solid black;

        &.addRow {
            background-color: lightgrey;
            width: 20px;
            min-width: 20px;
            height: 20px;
            text-align: center;
            cursor: pointer;
        }

        &.hidden {
            border: none;
        }
    }
}

.switchLabel {
    font-size: 10px;
    text-decoration: underline;

    color: var(--brightBlue);

    &_hidden {
        color: var(--redAlarm);
    }
}

.switchButton {
    font-size: 10px;
}
