
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.mathField {
    overflow: hidden;
    height: 100%;

    position: relative;
    background: transparent;
    color: #fff;
    cursor: default;
}

.contentWrapper {
    display: block;
}

.content {
    cursor: text;
    user-select: none;

    display: table-cell;
    position: relative;
    overflow: hidden;
    min-width: 100%;
    min-height: 100%;
    vertical-align: top;
    outline: none;
    padding: 3px 3px 3px 0;
}

.selectionContainer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.selectionCursor {
    width: 2px;
    margin-left: -1px;
    position: absolute;
    background-color: #bbf021;
}

.selectionPointer {
    position: fixed;

    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #bbf021;

    transform: translateX(-50%);
    z-index: 1999;

    touch-action: none;
    -webkit-touch-action: none;
}

.scrollbarContent {
    height: 100%;
}

.inputWrapper {
    overflow: hidden;
    display: block;
}

.readonly {
    user-select: none;
}
