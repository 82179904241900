
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.viewPoint {
    pointer-events: none;
}

.eventTarget {
    pointer-events: visiblePainted;
    touch-action: none;
}
