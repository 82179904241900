
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.container {
    position: relative;
    width: 100%;
}

.wrapper {
    @extend .container;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 24px;
}

.block {
    @extend .container;
    padding: 16px;
    border: 2px solid darkorange;

    &.transparent {
        opacity: 0.2;
    }

    h2 {
        font-size: 16px;
    }

    ul {
        list-style-type: none;
        margin-top: 16px;

        li {
            &:not(:first-child) {
                margin-top: 4px;
            }

            a {
                text-decoration: none;
                color: blue;

                &:hover,
                &:focus {
                    text-decoration: underline;
                }
            }
        }
    }
}
