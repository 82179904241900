
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.buttons {
    display: inline-grid;
    grid-auto-flow: column;
    grid-gap: 2px;
    position: absolute;
    top: 0;
    right: 0;
}

.button {
    background-color: #324C68;
    width: 32px;
    height: 32px;
    border: none;
    outline: none;
    padding: 0;

    &__icon {
        width: 100%;
        height: 100%;
        background-color: #439BFF;
    }
}
