
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.inline {
    display: inline-block;
}

.derivative {
    width: 0.1em;
    height: 0.3em;
    background: currentColor;
    transform: rotate(15deg);
    vertical-align: top;
    margin-left: 0.2em;
}
