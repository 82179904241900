
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.wrapper {
    padding: 20px;
    border: 1px solid #000;
}

.editorBlock {
    //position: relative;

    &:not(:last-child) {
        padding-bottom: 5px;
        border-bottom: 1px solid;
    }
}
