
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.g {
    &.hidden {
        visibility: hidden;
    }
}

.rect {
    --rotate: 0;
    --color: #fff;

    width: 20px;
    height: 20px;
    transform: translate(-50%, -50%) rotate(calc(var(--rotate) * -1deg - 45deg)) translate(50%, 50%);
    transform-box: fill-box;
    transform-origin: center center;

    stroke-dasharray: 2 2;
    stroke-dashoffset: 3;
    fill: transparent;
    stroke: var(--color);

    &.hidden {
        stroke: none;
    }
}

.circle {
    pointer-events: none;
    stroke-width: 1px;
    fill: none;
}

.svgInner {
    overflow: visible;
}
