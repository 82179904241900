
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.wrapper {
    position: relative;

    --desktop-keyboard-button-size: 25px;
}

.scrollbarContent {
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
    min-width: 500px;
}

.keyboard {
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    gap: 6px;
}

.columnCalculationsButtonIcon {
    width: 100%;
    height: 100%;
}
