
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.multitude {
    position: relative;
    width: 1em;
    height: 0.8em;

    &::before {
        font-size: 1em;
        font-weight: normal;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}

.apert {
    &::before {
        content: '⊃';
    }
}

.crossing {
    &::before {
        content: '⋂';
        transform: translate(-50%, -45%);
    }
}

.subset {
    &::before {
        content: '⊂';
    }
}

.union {
    &::before {
        content: '⋃';
        transform: translate(-50%, -45%);
    }
}
