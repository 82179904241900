
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.button {
    background: #2d3f56;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: 0;
    border: 0;
    color: #acd7ff;
    cursor: pointer;
    font-family: inherit;
    user-select: none;
    padding: 0;
    margin: 0;

    &:active {
        background: #324c68;
        text-shadow: 0px 2px 4px rgba(172, 215, 255, 0.5);
    }
}

.blurred {
    filter: blur(3px);
    pointer-events: none;
}
