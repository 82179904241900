
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.angle {
    position: relative;
    width: 1em;
    height: 1em;
    transform: translateY(0.1em);

    color: currentColor;
}
