
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.visibleLine {
    pointer-events: none;

    &._puncture {
        stroke-dasharray: 4 2;
    }
}

.visibleFill {
    pointer-events: none;
    touch-action: none;
}


.eventTriggerLine {
    pointer-events: visiblePainted;
    touch-action: none;
}
