
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.list {
    position: absolute;
    left: 110%;
    top: 0;
    width: 300px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    z-index: 2;
    margin: 0;
    padding: 0;

    > .listItem {
        background-color: #fff;
        padding: 5px;
        list-style: none;
        cursor: pointer;

        &:not(:last-child) {
            border-bottom: 1px solid gray;
        }

        &:hover {
            background-color: #388ae5;
            color: #fff;
        }
    }
}
