
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.icon {
    circle,
    path[stroke],
    rect[stroke] {
        stroke: currentColor;
    }

    path[fill],
    rect[fill] {
        fill: currentColor;
    }
}
