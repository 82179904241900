
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.watermarkWrapper {
    position: absolute;
    transform: translate(-50%, -50%);
    --color: white;
}

.watermark {
    width: 8px;
    height: 8px;
    background: var(--color, white);
    border-radius: 50%;
    display: flex;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.valueWrapper {
    width: 0;
    height: 0;
    position: relative;
    display: flex;

    &.middle {
        align-items: center;
        justify-content: center;
    }

    &.top {
        align-items: flex-end;
    }

    &.bottom {
        align-items: flex-start;
    }

    &.left {
        justify-content: flex-end;
    }

    &.right {
        justify-content: flex-start;
    }
}

.value {
    pointer-events: none;
    user-select: none;
    position: absolute;

    &.left {
        transform: translateX(-5px);
    }

    &.right {
        transform: translateX(5px);
    }
}
