
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.selectionCursor {
    width: 2px;
    margin-left: -1px;
    position: absolute;
}

.selectionCursorInner {
    top: -2px;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    background-color: #bbf021;
}
