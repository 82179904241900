
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.placeholder {
    display: inline-block;
    position: relative;

    &::before {
        content: '0';
        visibility: hidden;
        padding: 0.1em 0.08em 0;
    }

    &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: 1px dotted currentColor;
        box-sizing: border-box;
        opacity: 0.6;
    }
}
