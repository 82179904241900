
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.wrapper {
    position: relative;
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: baseline;
    margin: 0 0.1em;
}

.baseline {
    display: inline-block;
}

.content {
    display: inline-block;
    margin: 0.15em 0;
}

.container {
    display: inline-block;
}

.verticalLine {
    display: inline-block;
    width: 1px;
    background-color: currentColor;
    align-self: stretch;
    margin: 0 0.1em;
}
