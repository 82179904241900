
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.punctuationSymbol {
    font-size: 1em;
}

.delta {
    position: relative;
    width: 1em;
    height: 1em;

    &::before {
        content: 'Δ';
        position: absolute;
        left: 0;
        top: 0;
        font-size: 1.3em;
    }
}

.infinity {
    vertical-align: bottom;

    &__icon {
        vertical-align: middle;
        width: 1em;
        height: 0.9em;
        margin-top: 0;
        margin-bottom: 0;
        box-sizing: border-box;
    }
}
