
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.withEquals {
    position: relative;

    &::before {
        content: '';
        position: absolute;
        width: 90%;
        height: 0.07em;
        background: currentColor;
        border-radius: 50px;
        left: 0.04em;
        bottom: 0.13em;
    }
}

.notEquals {
    position: relative;
    display: inline-flex;
    align-items: center;
    margin: 0 0.2em;

    &::before {
        content: '';
        position: absolute;
        width: 150%;
        top: 50%;
        left: -25%;
        height: 0.07em;
        background: currentColor;
        border-radius: 50px;
        transform: rotate(-45deg);
    }
}
