
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.wrapper {
    min-width: 600px;
    height: calc(100% - 34px);
    display: flex;
    flex-direction: column;
}

.body {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 20px;
    justify-content: start;
}

.content {
    display: grid;
    grid-template-columns: 1fr;
    align-content: start;
    justify-items: start;

    gap: 8px;
}

.imageWrapper {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.watermarksBlock {
    position: absolute;
}

.image {
    max-width: 100%;
    max-height: 100%;
    user-select: none;
    pointer-events: none;
    position: absolute;
}

.buttons {
    display: grid;
    grid-template-rows: repeat(2, 40px);
    grid-gap: 5px;
    width: 100%;
    margin-top: 40px;
}

.frameWrapper {
    width: 400px;
    height: 400px;
}

.frame {
    height: 100%;
}

.frameContent {
    padding: 0;
}

.watermarksList {
    margin-top: 10px;
    display: grid;
    grid-row-gap: 10px;
}
