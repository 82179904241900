
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.wrapper {
    display: inline-block;
    position: relative;

    --toolbar-button-background: #2d3f56;
    --toolbar-button-background-active: #324c68;
    --toolbar-button-background-disabled: #45586c;
    --toolbar-button-color: #acd7ff;
}

.stub {
    display: block;
    width: 36px;
    height: 20px;
    pointer-events: none;
    margin-bottom: 2px;

    background-color: var(--toolbar-button-background);
}

.buttons {
    position: relative;
    width: 36px;

    display: grid;
    grid-auto-flow: row;
    grid-gap: 2px;

    background: linear-gradient(261.38deg, #1a2b41 -1.44%, #1d334b 46.29%, #1a2b41 100.39%);
}

.menu {
    --toolbar-button-background: #2d3f56;
    --toolbar-button-background-active: #324c68;
    --toolbar-button-background-disabled: #45586c;
    --toolbar-button-color: #acd7ff;

    display: inline-grid;
    grid-auto-flow: row;
    grid-template-columns: 1fr;
    grid-gap: 2px;

    background: linear-gradient(261.38deg, #1a2b41 -1.44%, #1d334b 46.29%, #1a2b41 100.39%);

    &.nested {
        position: absolute;
        margin: -2px 0;
        padding: 2px;
        box-shadow: 0 4px 10px 0 #0550a633, 0 4px 80px 0 #439bff1a;
    }
}
