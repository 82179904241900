
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.input {
    display: inline-flex;
}

.wrapper {
    display: inline-flex;
    vertical-align: middle;
    margin: 0 2px;
}

.redFrame {
    border: 1px solid #ff603d;
    box-shadow: 0 0 10px #ff603d;
}
