
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.input {
    display: block;
    min-height: 24px;
    flex-grow: 1;
    padding: 0 8px;
}
