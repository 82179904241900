
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.langMenu {
    background-color: #2d3f56;
    box-shadow: 0 4px 10px 0 #0550a633, 0 4px 80px 0 #439bff1a;
}

.langMenuItem {
    border: none;
    user-select: none;
    cursor: pointer;

    background-color: unset;
    padding: 12px;
    color: #acd7ff;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;

    .langMenuItemLabel {
        margin-left: 4px;
        font-size: 14px;
    }

    &:not(.selected) {
        .langMenuItemLabel {
            display: inline-block;
            opacity: 0.5;
        }

        .langMenuItemIcon {
            path {
                fill-opacity: 0.15;
            }
        }
    }
}
