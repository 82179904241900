
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.content {
    margin-bottom: 32px;
}

.buttons {
    display: grid;
    grid-auto-flow: column;
    justify-content: end;
    grid-gap: 8px;
}

.button {
    --buttonColor: black;
    --buttonTextShadowFirst: #{transparentize(black, 0.3)};
    --buttonTextShadowSecond: #{transparentize(black, 0.7)};
}
