
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.wrapper {
    width: 100%;
}

.tableContainer {
    width: 100%;

    overflow: auto;
}

.title {
    margin-bottom: 10px;
}
