
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.tooltipWrapper {
    position: absolute;

    bottom: 0;
    left: 0;
    right: 48px;
}

.tooltip {
    background-color: #4c729d;
    padding: 2px;
    box-shadow: 0 2px 12px 3px rgba(#fff, 0.15);

    display: inline-grid;
    grid-auto-flow: column;
    grid-gap: 2px;

    &__label, &__button {
        background-color: #0e233a;
    }

    &__label {
        color: white;
        font-size: 0.8rem;

        display: flex;
        align-items: center;
        padding: 4px 12px;
        min-height: 36px;
    }

    &__button {
        border: none;
        outline: none;
        padding: 8px;

        &__icon {
            width: 28px;
            height: 28px;
            background-color: white;
        }
    }
}
