
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.frame1 {
    overflow: hidden;

    clip-path: polygon(0 19%, 2.5% 0, 97.5% 0, 100% 19%, 100% 100%, 0 100%);
    background-color: var(--darkBlueOpal100);
    padding: 2px;
}

.frame2 {
    height: 100%;
    overflow: hidden;

    clip-path: polygon(0 19%, 2.5% 0, 97.5% 0, 100% 19%, 100% 100%, 7px 100%, 7px calc(100% - 17px), 0 calc(100% - 21px));
    background-color: #1B324D;
}
