
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.block {
    display: grid;
    gap: 2px;
    color: #fff;
    grid-template-columns: minmax(auto, 1fr);
}

.pressedShift {
    background: #324c68;
    text-shadow: 0px 2px 4px rgba(172, 215, 255, 0.5);
}

.bottomBlock {
    display: grid;
    grid-template: var(--desktop-keyboard-button-size) / var(--desktop-keyboard-button-size) 1fr;
    gap: 2px;
}

.ruBottomBlock {
    grid-template: var(--desktop-keyboard-button-size) / calc(var(--desktop-keyboard-button-size) * 0.785) 1fr;
}

.ruTop {
    grid-template:
        repeat(3, var(--desktop-keyboard-button-size))
        / repeat(10, calc(var(--desktop-keyboard-button-size) * 0.785));
}

.ruBottom {
    grid-template:
        var(--desktop-keyboard-button-size) /
        1fr calc(var(--desktop-keyboard-button-size) * 0.785) calc(var(--desktop-keyboard-button-size) * 0.785);
}

.enBottom {
    grid-template:
        var(--desktop-keyboard-button-size) /
        var(--desktop-keyboard-button-size) 1fr var(--desktop-keyboard-button-size);
}

.greekBottom {
    grid-template:
        var(--desktop-keyboard-button-size) /
        1fr var(--desktop-keyboard-button-size);
}

.greekIcon {
    font-family: Roboto, sans-serif;
}
