
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.inline {
    display: inline-block;
    transform: translateY(-0.1em);
}

.wrapper {
    transform: translateY(0);
}
