
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.topContainer {
    position: absolute;
    top: 0;
    right: 0;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
    justify-content: flex-end;

    gap: 2px;
    height: 32px;
}

.leftContainer {
    position: absolute;
    left: 0;
    top: 0;

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: stretch;
    justify-content: flex-start;

    gap: 2px;
    width: 36px;

    background-color: #1B2F46;
}

.item {
    flex-grow: 0;
    flex-shrink: 0;
}

.rect {
    width: 100%;
    height: 20px;

    background-color: var(--darkBlueOpal-light);
}
