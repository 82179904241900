
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.layout {
    border: 1px solid orange;
    padding: 10px;

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: stretch;
    justify-content: flex-start;
    gap: 8px;
}

.title {
    margin: 0;
}

.deleteButton {
    display: block;

    &__icon {
        vertical-align: bottom;
        display: inline-block;
        width: 16px;
        height: 16px;
        margin-right: 4px;
        mask-image: url("~@viuch/assets/icons/delete.svg");
        mask-repeat: no-repeat;
        mask-size: contain;
        mask-position: center;
        mask-origin: content-box;
        background-color: currentColor;
    }
}

.colorButton {
    width: 24px;
    height: 24px;

    background-color: var(--color);
}

.colors {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;

    gap: 8px;
}

.signs {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
    justify-content: flex-start;
    gap: 2px;

    &Button {
        padding: 0;
        width: 24px;
        height: 24px;
        background-color: var(--color);
        border: 1px solid black;
        margin: 0;
        border-radius: 2px;

        &._active {
            background-color: var(--goodGreen);
        }
    }
}

.row {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
    gap: 4px;
}
