
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.container {
    position: relative;
    width: 100%;
}

.label {
    @extend .container;
    display: flex;
    flex-direction: column;

    &:not(:first-child) {
        margin-top: 12px;
    }

    input {
        @extend .container;
        display: block;
        background-color: white;
        border: 1px solid black;
        padding: 4px 8px;
        margin-top: 4px;
    }
}

.hasError {
    input {
        border: 2px solid red;
    }
}

.button {
    height: 25px;
    min-width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 16px;
    margin-top: 24px;
    cursor: pointer;

    &:disabled {
        cursor: not-allowed;
        opacity: 0.6;
    }
}

.error {
    font-size: 14px;
    color: red;
}
