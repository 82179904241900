
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.inlineWrapper {
    display: inline-block;
    margin-top: 5px;
}

.grade {
    display: inline-block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    border: 2px solid currentColor;
    margin-top: -5px;
    vertical-align: top;
}
