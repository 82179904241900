
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.rows {
    display: grid;
    grid-template-columns: repeat(11, 1fr);
    grid-auto-rows: auto;
    grid-gap: 2px;
}

.row {
    display: contents;
}
