
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.modalBackdrop {
    --backdropBackground: transparent;
    padding: 30px 30px 200px;
}

.area {
    position: relative;
    height: 100%;
}

.modalFrame {
    height: 100%;

    --dropShadowLarge: transparent;
    --dropShadowSmall: transparent;
    --frameOuterColor: orange;
    --frameInnerColor: white;
}

.modalLayout {
    --modalCloseDropShadowLarge: lightgray;
    --modalCloseDropShadowSmall: lightgray;
    --modalCloseColor: grey;
}

.draggable {
    cursor: move;
}

.modalChildren {
    max-height: 70vh;
    overflow: auto;
}
