
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.mathInputWrapper {
    padding: 4px 8px;
    border: 1px solid black;
    border-radius: 2px;

    display: grid;
    grid-template-columns: minmax(0, 1fr) auto;
    gap: 8px;
}

.mathInput {
    color: black;
    //margin-bottom: -4px;
}

.row {
    display: grid;
    grid-template-columns: minmax(0, 1fr) auto;
    gap: 8px;
}

.buttons {
    display: grid;
    grid-auto-flow: column;
    gap: 4px;
}

.copyButton {
    width: 24px;
    height: 24px;

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;


    &::before {
        content: '';
        display: block;
        background-color: currentColor;

        width: 16px;
        height: 16px;

        mask-image: url("~@viuch/assets/icons/copy.svg");
        mask-repeat: no-repeat;
        mask-size: contain;
        mask-position: center;
        mask-origin: content-box;
    }
}

.portal {
    position: fixed;

    top: var(--top);
    left: var(--left);
}
