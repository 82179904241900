
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.block {
    --defaultColor: #fff;
    --focusColor: #bbf021;

    position: absolute;
    color: var(--defaultColor);
    user-select: none;
    transform: translateY(-5px);

    &:focus {
        color: var(--focusColor);
        z-index: 2;

        .mathField {
            color: var(--focusColor);
        }

        .dot {
            background-color: var(--focusColor);
            border-color: var(--focusColor);
            box-shadow: 0 0 5px rgba(187, 240, 33, 0.7),
                0 0 50px rgba(187, 240, 33, 0.1),
                inset 0 0 7.46221px rgba(187, 240, 33, 0.8);

            &.puncture {
                background-color: #2d3f56;
                box-shadow: 0 0 5px rgba(187, 240, 33, 0.7),
                    0 0 50px rgba(187, 240, 33, 0.1);
            }
        }
    }
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;

    position: absolute;
    transform: translateX(-50%);
}

.dot {
    width: 8px;
    height: 8px;
    border: 2px solid var(--defaultColor);
    border-radius: 50%;
    background: var(--defaultColor);
    position: relative;

    &::before {
        content: '';
        position: absolute;
        width: 14px;
        height: 14px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    &.puncture {
        background-color: #2d3f56;
    }
}

.dotInput {
    position: absolute;
    padding: 0;
    pointer-events: none;
    background: transparent;
    overflow: visible;
}

.mathField {
    overflow: visible;
}

.mathFieldContent {
    padding: 0;
    transform: translateY(15px);
}
