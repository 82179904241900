
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.button {
    --frameBevel: 10px;
    --height: 36px;
    --fontSize: 10px;

    position: relative;

    height: var(--height);
    border: none;
    outline: none;
    background: none;
    padding: 0;
    margin: 0;
    text-align: center;

    max-width: 100%;

    &__block {
        width: 100%;
    }

    &:not(:disabled) {
        cursor: pointer;
    }

    --buttonColor: var(--primary-green);
    --buttonBackground: var(--blue-alpha15);
    --buttonTextShadowFirst: var(--primary-green-alpha70);
    --buttonTextShadowSecond: var(--primary-green-alpha10);

    &__secondary {
        --buttonColor: var(--blue);
        --buttonTextShadowFirst: transparent;
        --buttonTextShadowSecond: transparent;

        &:active {
            --buttonBackground: var(--blue-alpha15);
        }
    }

    &:hover {
        --buttonBackground: var(--blue-alpha25);
    }
    &:active {
        --buttonBackground: var(--primary-green-alpha10);
    }
    &:disabled {
        --buttonColor: var(--blue-alpha25);
        --buttonBackground: transparent;
    }
    &:active,
    &:disabled {
        --buttonTextShadowFirst: transparent;
        --buttonTextShadowSecond: transparent;
    }
}

.frame {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--buttonColor);

    clip-path: polygon(
        2px 2px,
        0 0,
        calc(100% - var(--frameBevel)) 0,
        100% calc(var(--frameBevel) - 0px),
        100% 100%,
        0 100%,
        0 0,
        2px 2px,
        2px calc(100% - 2px),
        calc(100% - 2px) calc(100% - 2px),
        calc(100% - 2px) calc(var(--frameBevel) - 0px),
        calc(100% - var(--frameBevel) - 1px) 2px
    );
}

.inner {
    @extend %title5;

    font-size: var(--fontSize);

    line-height: var(--height);
    height: 100%;
    padding: 0 12px;

    color: var(--buttonColor);
    background-color: var(--buttonBackground);

    clip-path: polygon(
        2px 2px,
        2px calc(100% - 2px),
        calc(100% - 2px) calc(100% - 2px),
        calc(100% - 2px) calc(var(--frameBevel) - 2px),
        calc(100% - var(--frameBevel) - 1px) 2px
    );

    // text-shadow: 0 0 5px var(--buttonTextShadowFirst), 0 0 50px var(--buttonTextShadowSecond);

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
