
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.outer {
    --frame-outer: var(--darkBlueOpal100);
    --frame-inner: #1b324d;

    clip-path: polygon(
            19px 0,
            100% 0,
            100% calc(100% - 28px),
            calc(100% - 26px) 100%,
            0 100%,
            0 17px
    );

    background-color: var(--frame-outer);
}

.inner {
    clip-path: polygon(
            20px 2px,
            calc(100% - 2px) 2px,
            calc(100% - 2px) calc(100% - 29px),
            calc(100% - 27px) calc(100% - 2px),
            2px calc(100% - 2px),
            2px 18px
    );

    padding: 2px;
    background-color: var(--frame-inner);
}
