
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.viewport {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    touch-action: none;

    display: flex;
    align-items: center;
    justify-content: center;
}

.canvas {
    position: absolute;

    --width: 400;
    --height: 400;
    --scale: 2;

    --position-x: 0.5;
    --position-y: 0.5;

    --position-x_: calc(50% - var(--position-x) * 100%);
    --position-y_: calc(var(--position-y) * 100% - 50%);

    width: calc(var(--width) * var(--scale) * 1px);
    height: calc(var(--height) * var(--scale) * 1px);

    transform: translate(var(--position-x_), var(--position-y_));
}

.canvasGrid {
    pointer-events: none;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
