
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.wrapper {
    height: 40px;
    font-size: 20px;
    line-height: 20px;
    text-align: center;
    font-weight: bold;

    display: flex;
    align-items: center;
    justify-content: center;

    &::after {
        content: ' ';
    }
}

.cellPlus {
    &::after {
        content: '+';
        color: forestgreen;
    }
}

.cellMinus {
    &::after {
        content: '-';
        color: orangered;
    }
}

.cellEmpty {
    &::after {
        content: '[empty]';
        color: lightgrey;
        font-size: 10px;
    }
}

.cellNull {
    &::after {
        content: '[не выбрано]';
        color: orange;
        font-size: 10px;
    }
}
