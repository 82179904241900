
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.inline {
    display: inline-block;
}

.wrapper {
    display: inline-flex;
    flex-direction: column;
    margin: 0 0.1em;
}

.downWrapper {
    display: inline-flex;
    align-items: center;
    font-size: 0.7em;
}

.icon {
    margin: 0 0.2em;
}
