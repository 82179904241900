
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
    justify-content: flex-start;
    gap: 2px;

    position: relative;

    user-select: none;
    cursor: pointer;

    --bg-color: var(--darkBlueOpal-light);
    --icon-color: var(--blue);

    &:hover {
        --bg-color: var(--darkBlueOpal100);
    }

    &._disabled {
        --bg-color: var(--darkBlueOpal-light);
        --icon-color: var(--blue-alpha15);
        cursor: initial;
    }
}

.root {
    width: 36px;
    height: 36px;

    flex-shrink: 0;
    flex-grow: 0;

    background-color: var(--bg-color);
    color: var(--icon-color);

    position: relative;
}

.tooltip {
    pointer-events: none;
    position: absolute;

    top: 0;
    bottom: 0;
    left: calc(100% + 2px);
    outline: 2px solid #1B2F46;

    width: calc(170px - 4px);
}

.label {
    flex-grow: 1;
    flex-shrink: 1;
}

.tooltip,
.label {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;

    padding: 0 4px;
    cursor: inherit;

    background-color: var(--bg-color);
    color: var(--icon-color);
    @extend %paragraph4;
}
